<template>
  <div
    :class="['top-panel__container', {
      'top-panel__container--old-view': isOldView,
    }]">
    <div class="top-panel__notifications">
      <CyAlert
        v-if="!_.isEmpty(errors)"
        class="width-100"
        theme="error"
        :title="$t('infraViewConfigErrorTitle')"
        :content="errors"/>
    </div>

    <div
      :class="['top-panel__content', {
        'top-panel__content--old-view': isOldView,
      }]">
      <div class="left-box">
        <template v-if="!isOldView">
          <CyButtonToggle
            v-if="!isOldView && hasDiagram"
            v-model="currentMode"
            :items="$static.modesItems"
            class="mode-switch"/>
        </template>
      </div>

      <!-- TODO: FE#4336 IV 3.0: Remove the old IV and add refresh date and button here -->
      <CyMenu
        offset-y
        bottom
        left
        origin="top right"
        :items="$static.viewActions"
        @click="toggleOldView"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import CyButtonToggle from '@/components/CyButtonToggle.vue'

export default {
  name: 'CyInfraViewTopPanel',
  components: {
    CyButtonToggle,
  },
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('organization/project/infraview', {
      mode: (state) => state.mode,
    }),
    ...mapGetters('organization/project/infraview', [
      'hasDiagram',
    ]),
    $static () {
      return {
        modesItems: [
          {
            key: 'diagram',
            value: 'diagram',
            text: this.$t('mode.diagram'),
          },
          {
            key: 'list',
            value: 'list',
            text: this.$t('mode.list'),
          },
        ],
        viewActions: [{
          icon: 'history_toggle_off',
          label: this.isOldView ? this.$t('useNewVersion') : this.$t('useOldVersion'),
          action: this.toggleOldView,
        }],
      }
    },
    currentMode: {
      get () {
        return this.mode
      },
      set (mode) {
        this.SET_MODE({ mode })
      },
    },
    isOldView () {
      return this.mode === 'oldVersion'
    },
  },
  methods: {
    ...mapMutations('organization/project/infraview', [
      'SET_MODE',
    ]),
    toggleOldView () {
      this.mode === 'oldVersion'
        ? this.SET_MODE({ mode: 'diagram' })
        : this.SET_MODE({ mode: 'oldVersion' })
    },
  },
  i18n: {
    messages: {
      en: {
        infraViewConfigErrorTitle: 'InfraView configuration error',
        mode: {
          diagram: 'Diagram',
          list: 'List',
        },
        useNewVersion: 'Use the new version',
        useOldVersion: 'Use previous version',
      },
      es: {
        infraViewConfigErrorTitle: 'Error de configuración de InfraView',
        mode: {
          diagram: 'Diagrama',
          list: 'Lista',
        },
        useNewVersion: 'Usar la nueva versión',
        useOldVersion: 'Usar la versión anterior',
      },
      fr: {
        infraViewConfigErrorTitle: 'Erreur de configuration InfraView',
        mode: {
          diagram: 'Diagramme',
          list: 'Liste',
        },
        useNewVersion: 'Utiliser la nouvelle version',
        useOldVersion: 'Utiliser la version précédente',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.top-panel {
  &__container {
    position: absolute;
    z-index: 101;
    top: 0;
    width: 100%;

    &--old-view {
      top: $spacer-5;
      right: 0;
      width: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: #{$spacer-4} #{$spacer-6} 0;

    .left-box {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .env-list {
      max-width: 200px;
      margin: 0;
      margin-right: 24px;

      ::v-deep .v-input__slot {
        margin-bottom: 0;
      }
    }

    .mode-switch {
      margin-bottom: -2px;
    }

    &--old-view {
      padding: #{$spacer-4} #{$spacer-6} 0 0;
    }
  }
}

.v-btn.btn-switch-version {
  align-self: center;
  font-size: $font-size-sm;
}
</style>
